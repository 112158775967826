<template>
  <div class="auth-wrapper auth px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo.png"
            alt="statuspro.club/"
            class="navbar-brand-full"
          /></b-link>

        <b-card-title class="mb-1 text-center">
          Добро Пожаловать!
        </b-card-title>
        <b-card-text class="mb-2">
          Для работы в личном кабинете необходимо войти в свою учетную запись или зарегистрироваться на портале.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label-for="phone"
              label="Телефон"
            >
              <validation-provider
                #default="{ errors }"
                name="телефон"
                rules="required|mobilephone"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  v-mask="'+7(###)###-##-##'"
                  name="login-phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="+79101001010"
                  autocomplete="off"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Пароль</label>
                <b-link :to="{ name:'auth-login-code' }">
                  <small>Забыли пароль?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="пароль"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    autocomplete="off"
                    placeholder="Пароль"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
            >
              Вход
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>Впервые у нас? </span>
          <b-link :to="{ name:'auth-register' }">
            <span>Зарегистрироваться</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mobilephone from '@/validations/mobileValidates'

import { signIn } from '@/application/authService'
import { fetchUserData } from '@/application/userService'
import { chekbasket } from '@/application/basketService'
import { fetchModeratorData } from '@/application/dashboardService'

import store from '@/store'
import router from '@/router'

localize('ru', ru)
extend('mobilephone', mobilephone)

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const toast = useToast()
    const loginForm = ref(null)
    const phone = ref('')
    const password = ref('')

    const validationForm = async () => {
      try {
        const success = loginForm.value.validate()
        if (success) {
          await signIn(phone.value, password.value)
          await fetchUserData()
          await fetchModeratorData()
          await chekbasket()
          await router.replace('/')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Добро пожаловать ${store.state.user.userData.firstName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Вы успешно вошли в систему',
            },
          })
        }
      } catch (error) {
        loginForm.value.setErrors(`${error}`)
        store.commit('app/SET_ERROR', error)
      }
    }

    return {
      loginForm,
      phone,
      password,

      validationForm,
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/innst-auth.scss';
</style>
