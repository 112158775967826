export default {
  message() {
    return 'номер телефона не валидный'
  },
  validate(value) {
    // eslint-disable-next-line no-useless-escape
    const MOBILEREG = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/
    return MOBILEREG.test(value)
  },
}
